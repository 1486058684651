@import "spacing";

// modified in the template and document sass respectively
.nodeNumberingIndex {
    min-width: $nodeMargin - $std-space;
    margin-right: $std-space;
    display: inline-block;
    text-align: left;
    text-indent: 0; }

.bold {
    font-weight: bold; }

.caps {
    text-transform: uppercase; }

.underline {
    text-decoration: underline; }

.italic {
    font-style: italic; }

$line-spacing: $std-space * 2;
[linespacing="1"] {
    margin-bottom: $line-spacing * 1; }

[textindent="1"] {
    text-indent: $nodeMargin * 1; }

[textindent="2"] {
    text-indent: $nodeMargin * 2; }

[textindent="3"] {
    text-indent: $nodeMargin * 3; }

[textindent="4"] {
    text-indent: $nodeMargin * 4; }

[textindent="5"] {
    text-indent: $nodeMargin * 5; }

[alignment="center"] {
    text-align: center; }

[alignment="right"] {
    text-align: right; }

[alignment="left"] {
    text-align: left; }
